import { useAuthUserStore } from "@/store/authUser";
const baseUrl = process.env.VUE_APP_API_URL_BASE;
//console.log(baseUrl)

export const apiFech = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }        
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    // const { user } = useAuthUserStore();
    const authUserStore = useAuthUserStore();
  

    // const isLoggedIn = user.apiKey ? true : false;
    const isApiUrl = url.startsWith(baseUrl);
    //console.log(user.apiKey)
    // console.log(isLoggedIn)
    // console.log(isApiUrl)
    if (authUserStore.isLoggedIn() && isApiUrl) {
        //console.log(authUserStore)
        return { Authorization: `Bearer ${authUserStore.user.apiKey}` };
        
    } else {
        return {};
    }
}

function handleResponse(response) {
    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            const { user, logout } = useAuthUserStore();
            if ([401, 403].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout();
            }

            const error = (data && data.error.msgError) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}    
