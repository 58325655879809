 <template>
    <div>
     
      <table class="table table-bordered table-striped table-hover" v-if="props.result">
        <thead>
          <tr>
            <th v-for="(column,index) in props.columns" :key="index">
              <div v-if="column.visible !== false">
                <div class="d-flex justify-content-between" >
                  <div >
                    {{column.name}}
                  </div>

                  <div v-if="column.type !== ''">
                      <a class="ml-1" href="#"><i @click="filterByOrder(column.key, 'ASC')" class="bi bi-arrow-up-short" style="color: black"></i></a>
                      <a class="ml-1" href="#"><i @click="filterByOrder(column.key, 'DESC')" class="bi bi-arrow-down-short" style="color: black"></i></a>
                  </div>

                </div>
                <input v-if="column.type ==='text'" type="text" class="form-control" v-model="filterLocal[column.key]" @blur="filterByField(filterLocal)"/>
                <input v-if="column.type ==='email'" type="email" class="form-control" v-model="filterLocal[column.key]" @blur="filterByField(filterLocal)"/>
                <input v-if="column.type ==='' " class="form-control-plaintext" readonly  />
                <select v-if="column.type === 'select'" class="form-select " v-model="filterLocal[column.key]"  @blur="filterByField(filterLocal)" >
                  <option v-for="item in column.options" :key="item" :value="item.value">{{item.name}}</option>
                </select>
              </div>
            </th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data,index) in props.result" :key="index" @click="clicar(data.numMemoria)">
            <td v-for="(column,index) in props.columns" :key="index">
              {{data[column.key]}} 
            </td>
            <td>
              <div class="text-center">
                <i class="bi bi-pencil-square" ></i>
              </div>
            
            </td>
          </tr> 
        </tbody>
      </table> 
      <p v-else>No matches found.</p> 
   
    </div>
  
</template>

<script setup>
import {defineProps , defineEmits , onMounted} from 'vue'


let filterLocal = {}


const props = defineProps(['result','columns','filter'])
 
console.log(props.result)
const emit = defineEmits(['aplyOrder','aplyFilter','botoClick'])

const filterByOrder = (order, campOrdre) =>{
    
    emit('aplyOrder',order,campOrdre)
} 

const filterByField = ( filter) => {
 
    emit('aplyFilter', filter)
    
}

const clicar = (id) => {
  emit('botoClick', id )
}


onMounted(() => {
    props.columns.map(column => filterLocal[column.key] = null)

})

// const filtrat = props.result.filter(item => item.idUsuariTecnic == '57')
// console.log(props.result)
// console.log(filtrat)

</script>


<style scoped>
.info{
    background-color: antiquewhite;
    width: 150px;
}
.table-hover tbody tr:hover td {
    background:#ffc107;
    cursor:pointer;
}

</style>