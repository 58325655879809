<template>
  <div class="navbar">
    <div class="barraSuperior">
      <div class="titolApartat">
        <span style="color:#e17029">Memòria {{ route.params.id }}</span>
      </div>
    </div>
  </div>
  <section class="container-fluid">
    <div class="navbar navbar-expand-lg justify-content-end menuOperacions">
      <a class="btn btn-outline-success btn-sm ms-1" @click="modificar">
            Modificar</a
          >
      <a
        class="btn btn-outline-secondary btn-sm navbar navber-expanded justify-content-end menuOperacions me-2"
        @click="tornar"
        >Tornar Llistat</a
      >
    </div>
    <div class="card mb">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div>
              <span class="control-label fw-bold">Num:</span>
            </div>
            <div class="my-1">
              <span class="text-uppercase">000000</span>
            </div>
            <div class="my-1">
              <span>Persona contacte obra</span>
            </div>
          </div>
          <div class="col-md-4">
            <span class="control-label fw-bold">Propietat:</span>
            <div class="my-1">
              <span class="text-uppercase">Nom Empresa</span>
            </div>
            <div class="my-1">
              <span>Persona contacte</span>
            </div>
          </div>

          <div class="col-md-4">
            <span class="control-label fw-bold">Empresa Coordinadora:</span>
            <div class="my-1">
              <span class="text-uppercase">Nom coordinadora</span>
            </div>
            <div class="my-1">
              <span>Centre</span>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-8">
            <label>Comentaris</label>
            <div>
              <textarea class="form-control">Comentaris</textarea>
            </div>
          </div>
          <div class="col-md-4">
            <label>Estat</label>
            <div>
              <select class="form-select">
                <option :value="1">1</option>
                <option :value="2">2</option>
                <option :value="3">3</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb">
      <div class="card-body">Imatges</div>
    </div>
    <div class="card mb">
      <div class="card-header">
        <a
          class="nav-link fs-6"
          style="color:#e17029"
          data-bs-toggle="collapse"
          href="#collapseSistemes"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Sistemes
          <i class="bi bi-arrow-down-short"></i>
        </a>
        <div class="collapse" id="collapseSistemes">
          <div class="card card-body">
            Sistemes
          </div>
        </div>
      </div>
    </div>
    <div class="card mb">
      <div class="card-header">
        <a
          class="nav-link fs-6"
          style="color:#e17029"
          data-bs-toggle="collapse"
          href="#collapseEpis"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Epis
          <i class="bi bi-arrow-down-short"></i>
        </a>
        <div class="collapse" id="collapseEpis">
          <div class="card card-body">
           Epis
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useMemoriesStore } from "../hooks/useMemories";
import {ref} from "vue"
const useMemories = useMemoriesStore()
const route = useRoute();
const router = useRouter();

const tornar = () => {
  router.push({ name: "memories" });
};

const memoriesLocal = ref(JSON.parse(localStorage.getItem("memories")))
console.log(memoriesLocal.value.numMemoria)
const modificar = () => {
  useMemories.memories.map((item) => {
    if (item.numMemoria == route.params.id) {
      item.flagEstatMemoria = "No finalitzat";
    }
    localStorage.setItem("memories", JSON.stringify(useMemories.memories));
  });
};
</script>

<style scoped>

</style>
