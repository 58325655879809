import { defineStore } from "pinia"
import { apiFech } from "@/composables/apiFetch";
const baseUrl = process.env.VUE_APP_API_URL_BASE + `/memories`;
export const useMemoriesStore = defineStore({
  id: "memories",
  state: () => ({
    memories: [],
    memoriesData: [],
    error: [],
    status: 0,
    statusMemoriesInfo: 0,
    filter: {},
    order: {'nomUsuari':'ASC'}
  }),

  getters: {
    getMemories(state) {
      return state.memories;
    },
    isLoading(state) {
      return (state.status == 2) ? true : false;
    },
    isError(state) {
      return (state.status == 3) ? true : false;
    },
    isLoadingMemoriesInfo(state){
      return (state.statusMemoriesInfo == 2 || state.statusMemoriesInfo == 0) ? true : false;
    },
    isErrorMemoriesInfo(state){
      return (state.statusMemoriesInfo == 3) ? true : false;
    }
  },

  actions: {

    

    //Retorna json de usuaris

    async fetchMemories() {
      //console.log("entro a fecth");
      this.status = 2;
      //console.log("parametres a fecth " )
    
      const memoria = await apiFech.get( baseUrl );
      
      if (memoria.status == "ok" && memoria.resultat) {
        this.memories = memoria.resultat.memories;
        localStorage.setItem("memories", JSON.stringify(this.memories));
        console.log(this.memories)

        this.status = 1;
      } else {
        //console.log("entro error");
        this.error = memoria.error;
        this.status = 3;
        return false;
      }
    },

    addOrder(campOrdre){
      this.order = campOrdre
    },
    
    addFilter(campFilter){
      this.filter = campFilter
    },

    // Retorna dades bàsiques de usuari

    async fetchMemoriesDataBasic() {
      // console.log("entro fecth memoriesData basiques");
      this.status = 2; 
      const fetchTMP = await apiFech.get(`${baseUrl}/${this.memoriesData.idUsuari}`);
      //console.log(fetchTMP);
      if (fetchTMP.status == "ok" && fetchTMP.result) {
        this.memoriesData = fetchTMP.result;
        this.status = 1;
        //console.log(this.memoriesData);
      } else {
        this.error = fetchTMP.error;
        this.status= 3;
        return false;
      }
    },

       // Retorna dades ampliades de usuari

    async fetchMemoriesDataInfo() {
      console.log("entro fecth memoriesData complertes");
     
    
     
      if(this.memoriesData.idUsuari){
        this.statusMemoriesInfo = 2;
        
        //console.log(this.memoriesData.idUsuari)
  
        const fetchTMP2 = await apiFech.get(baseUrl + "/info/" + this.memoriesData.idUsuari);

  
        if (fetchTMP2.status == "ok" && fetchTMP2.result) {
          this.memoriesData = fetchTMP2.result;
          console.log(this.memoriesData)
          this.statusMemoriesInfo = 1;
        
        } else {
          this.errror = fetchTMP2.error;
          this.statusMemoriesInfo = 3;
          return false;
        }
      }
     
    },

 

    loadMemoriesBasic(userId) {
      console.log('entra loadUserBasic')
      this.status = 2
     
      this.memoriesData.idUsuari = userId;
      
      this.fetchUserDataBasic()
    },

    loadMemoriesInfo(id) {
      this.statusMemoriesInfo=0
      console.log('entra loadUserInfo' + id)
      this.memoriesData.idUsuari=id 
      this.fetchMemoriesDataInfo();
      
    },

    

    async updateMemoriesData() {
      console.log("entra store novaInformacio" + this.memoriesData.idUsuari);

      const dataToPost = {
        nomUsuari: this.memoriesData.nomUsuari,
        cognomUsuari: this.memoriesData.cognomUsuari,
      };

      const fetchTMP3 = await apiFech.post(baseUrl + "/update/" + this.memoriesData.idUsuari,dataToPost);

      if (fetchTMP3.status == "ok" && fetchTMP3.result) {
        console.log("modificat ok")
        //this.fetchmemoriesDataInfo();
      
        location.reload()
      } else {
        this.errror = fetchTMP3.error;
        this.statusMemoriesInfo = 3;
        return false;
      }

      
    },



    
  },
});
