<template>
    <div class="d-flex ">
	<a class="noDisabled d-flex align-items-center ml" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="perfil nouredinne">
		<span class="mr d-none  d-md-block">Lluert Serveis Telemàtics S.L.</span>

        <div><span class="bi bi-circle-fill me-1" id="btn_online_<?= $usuariSessio->get('idUsuari') ?>"></span></div>
	
		<!-- AQUI VA LA FOTO USUARI -->
		
			<!-- SI TE FOTO USUARI -->
            <img class="rounded mx-auto d-block me-2" width="40" height="40" style="border-radius:50%;" alt="User Image" src="../assets/img/lluert_icon.png" />
		

			<!-- <span class="btn bg-primary border-white rounded-circle" style="colorLogin">NO</span> -->
	
	</a>
	<div class="dropdown-menu dropdown-menu-right">
		<div class="dropdown-header text-center">
			<img src="../assets/img/lluert_icon.png" class="rounded mx-auto d-block" width="100" alt="User Image">
			<em class="font-weight-bold">Lluert Serveis Telemàtics S.L.</em>
		</div>
		<div class="dropdown-divider"></div>
		
            <a class="noDisabled dropdown-item" @click="obreFitxa(9)" style="z-index: -1;"><i class="bi bi-person-fill me-1"></i>Perfil</a>
	
		
            <a class="noDisabled dropdown-item" @click="authUserStore.logout()" href="#" style="z-index: -1;"><i class="bi bi-x-lg me-1"></i>tancar sessió</a>
	</div>
</div>
</template>


<script setup>
import { useAuthUserStore } from "../store/authUser";
import {useRouter} from 'vue-router'



const router = useRouter()

const authUserStore = useAuthUserStore();


const obreFitxa = (id) => {
  router.push({name: 'usuarisFitxa', params: { id:id } })
        
}

</script>