import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'

//bootstrap

import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"

import "./css/estils_estructura.css"

const pinia = createPinia()
createApp(App).use(pinia).use(router).mount('#app')
