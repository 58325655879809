<template>
  <div class="navbar" style="padding: 0">
    <div class="barraSuperior">
      <div class="titolApartat">
        <h1>{{ userStore.userData.nomUsuari }}</h1>
      </div>
      <div class="navbar navbar-expand-lg menuVistes">
        <ul class="nav justify-content-start">
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Dades)}" @click="handleLayout(Dades)"><span>Dades Personals</span></a></li>
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Horaris)}" @click="handleLayout(Horaris)"><span>Horaris</span></a></li>
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Nomines)}" @click="handleLayout(Nomines)"><span>Nómines</span></a></li>
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Contracte)}" @click="handleLayout(Contracte)"><span>Contracte</span></a></li>
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Calendari)}" @click="handleLayout(Calendari)"><span>Calendari</span></a></li>
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Fitxatges)}" @click="handleLayout(Fitxatges)"><span>Fitxatges</span></a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <component :is="viewComponent" />
  </div>
</template>

<script setup>
// import CarregantDades from "../../components_generics/CarregantDades.vue"
import { defineAsyncComponent, shallowRef, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useUsersStore } from "../hooks/useUsuaris";

//-------VARIABLES-----------

//------COMPONENTS DINÀMICS------



const Dades = defineAsyncComponent(() => import("../views/DadesView.vue"));

const Horaris = defineAsyncComponent(() => import("../views/HorarisView.vue"));

const Nomines = defineAsyncComponent(() => import("../views/NominesView.vue"));

const Contracte = defineAsyncComponent(() => import("../views/ContracteView.vue"));

const Calendari = defineAsyncComponent(() => import("../views/CalendariView.vue"));

const Fitxatges = defineAsyncComponent(() => import("../views/FitxatgesView.vue"));

let viewComponent = shallowRef(Dades);

const handleLayout = (component) => {
  viewComponent.value = component;

};

const isActive = (component) => {
  if(viewComponent.value === component){
    return true
  }else{
    return false
  }
}

const userStore = useUsersStore();



//------FUNCIONS


onMounted(() => {
  
  const route = useRoute();
  console.log('entra onMounted usuaris fitxa' + route.params.id )
  userStore.loadUserBasic(route.params.id);
 

});
</script>

<style scoped>
span{
  color:#37474f;
}
</style>
