<template>
  <div class="navbar" style="padding: 0">
    <div class="barraSuperior">
      <div class="titolApartat d-flex">
        <span style="color: #e17029">Memòries</span>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="navbar menuOperacions navbar_capcalera">
      <button
        class="btn btn-outline-success"
        id="accions"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        dissabled
      >
        Accions
      </button>
    </div>
    <div class="collapse butons" id="collapseExample">
      <div class = "card card-body mb-2">

        <div class="d-flex justify-content-end ">
          <a class="btn btn-outline-primary btn-sm ms-1" @click="importar">
            Importar</a
          >
          <a class="btn btn-outline-secondary btn-sm ms-1" @click="publicar">
            Publicar</a
          >
        </div>

      </div>
    </div>
    <div v-if="useMemories.memories.length">
      <LlistaTable
        :result="useMemories.memories"
        :columns="gridColumns"
        @aplyFilter="aplyFilter"
        @botoClick="botoClick"
      />
    </div>
    <!-- <div v-else>
      <ErrorGeneric :error="error" />
    </div>-->
  </div>
</template>

<script setup>
import LlistaTable from "../../components_generics/LlistatTable.vue";
//import ErrorGeneric from "../../components_generics/ErrorGeneric.vue";
import Swal from "sweetalert2";

import { useRouter } from "vue-router";
import { useMemoriesStore } from "../hooks/useMemories";
import {  onMounted } from "vue";

//funcio desablitar accions si no tenim xarxa

const router = useRouter();

const useMemories = useMemoriesStore();

// const memoriesLocal = ref();

onMounted(() => {
  actualitzarXarxa()
  if (JSON.parse(localStorage.getItem("memories"))) {
    const memoriesLocal = JSON.parse(localStorage.getItem("memories"));
    useMemories.memories = memoriesLocal;
    //alert("recupero del local");
  } else {
    Swal.fire({
      title: "No tens memòries",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Importa",
      denyButtonText: `Cancela`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Importades", "", "success");
        useMemories.fetchMemories();
      } else if (result.isDenied) {
        Swal.fire("Importació cancel-lada", "", "info");
      }
    });
  }
});

const gridColumns = [
  {
    name: "Data",
    key: "dataMemoria",
    type: "text",
  },
  {
    name: "Estat",
    key: "flagEstatMemoria",
    type: "select",
  },
  {
    name: "Instal-lació",
    key: "numMemoria",
    type: "text",
  },
  {
    name: "Propietat",
    key: "nomClientInstalacio",
    type: "select",
  },

  {
    name: "Empresa Coordinadora",
    key: "nomClientCoordinacio",
    type: "select",
  },
];

// Desactivo botons de acció si no tinc xarxa
const actualitzarXarxa = () => {
  const desabilitat = document.querySelector("#accions");
  if (!window.navigator.onLine) {
    desabilitat.disabled = true;
    desabilitat.textContent = "Accions desabilitades";
    desabilitat.classList.add("btn-outline-danger");
    document.querySelector(".butons").classList.remove("show")
    
    return;
  } 
  else {
    desabilitat.disabled = false;
    desabilitat.textContent = "Accions";
    desabilitat.classList.remove("btn-outline-danger");
    return 
  }
};

window.addEventListener("online", actualitzarXarxa);
window.addEventListener("offline", actualitzarXarxa);

// memoriesLocal.value = JSON.parse(localStorage.getItem("memories"));
// console.log(memoriesLocal.value);

const aplyFilter = (filter) => {
  alert("filtrar" + filter);
};

const botoClick = (id) => {
  router.push({ name: "memoriesFitxa", params: { id: id } });
};

const importar = () => {
  useMemories.fetchMemories();
  Swal.fire({
  icon: 'success',
  title: 'Dades importades',
  
})
};

const publicar = () => {
 
  Swal.fire({
  icon: 'success',
  title: 'Dades publicades',
  
})

};
</script>

<style scoped></style>
