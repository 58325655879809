<template>
  <div v-show="authUserStore.isLoggedIn()" id="userBox">
    <UserBox />
  </div>
  <div class="main">
    <div id="llistat">
      <router-view />
      <div v-if="useAuthUserStore.getError">
        <ErrorGeneric />
      </div>
    </div>
  </div>
</template>

<script setup>
// import SideBar from "../src/components_generics/SideBar.vue";
import UserBox from "../src/components_generics/UserBox.vue";
import ErrorGeneric from "./components_generics/ErrorGeneric.vue";
import { useAuthUserStore } from "@/store/authUser";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const authUserStore = useAuthUserStore();

const router = useRouter()

onMounted(()=>{
  if(JSON.parse(localStorage.getItem("user"))){
    router.push({name:"memories"})
  }else{
    router.push({name:"Login"})
  }
})

//evitem refresca pàgina
window.onbeforeunload = function () {
  if (!window.navigator.onLine) {
    return "¿Desea recargar la página web?";
  }
};


</script>

<style></style>
