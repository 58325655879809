<template>
  <div class="navbar" style="padding: 0">
    <div class="barraSuperior">
      <div class="titolApartat d-flex">
        <h1>Gestio equip</h1>
        <!-- <button
          class="btn btn-outline-secondary btn-sm"
          @click="handleLayout(LlistatTable)"
        >
          Llista
        </button>
        <button
          class="btn btn-outline-secondary btn-sm"
          @click="handleLayout(LlistatCard)"
        >
          Tarjeta
        </button> -->
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="navbar menuOperacions navbar_capcalera">
      <button v-if="isActive(LlistatCard)" class="btn btn-outline-secondary btn-sm " @click="viewComponent=LlistatTable"><i class="bi bi-list-ul"></i></button>
      <button v-else-if="isActive(LlistatTable)" class="btn btn-outline-secondary btn-sm "  @click="viewComponent=LlistatCard"><i class="bi bi-person-vcard"></i></button>
      
      <a
        class="btn btn-outline-secondary btn-sm"
        data-bs-toggle="collapse"
        href="#collapseFilterAdvanced"
        role="button"
        aria-expanded="false"
        aria-controls="collapseFilterAdvanced"
      >
        Filtrar per camp
      </a>
      
      
    </div>
    <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
      <div class="card card-body mb-2">
        <FilterAdvanced
          :columns="gridColumns"
          :filter="filter"
          @aplyFilter="aplyFilter"
        />
      </div>
    </div>

    <component
      :is="viewComponent"
      :result="userStore.users"
      :columns="gridColumns"
      :filter="filter"
      @aplyOrder="aplyOrder"
      @aplyFilter="aplyFilter"
      @draggableOrder="draggableOrder"
      @botoClick="botoClick"
    />
  </div>
</template>

<script setup>
import { useUsersStore } from "../hooks/useUsuaris";
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";
import {useRouter} from 'vue-router'

import { ref, onMounted, shallowRef ,defineAsyncComponent} from "vue";


//------COMPONENTS DINÀMICS------

// import LlistatCard from '../../components_generics/LlistatCard.vue'
// import LlistatTable from '../../components_generics/LlistatTable.vue'



const LlistatCard = defineAsyncComponent(() =>
  import("../../components_generics/LlistatCard.vue")
);
const LlistatTable = defineAsyncComponent(() =>
  import("../../components_generics/LlistatTable.vue")
);

const viewComponent=shallowRef(LlistatTable)

//------VARIABLES------

const userStore = useUsersStore();
const router = useRouter()
const result = ref([]);
// const orderBy = ref({});
let filter = { actiuUsuari: 1 };



const gridColumns = [
  {
    name: "Usuari",
    key: "nomUsuari",
    type: "text",
    visible:true,
  },
  {
    name: "Direcció",
    key: "direccioUsuari",
    type: "text",
    visible:true,
  },
  {
    name: "Prova filtre",
    key: "provaFiltre",
    type: "text",
    visible : false,
  },
  {
    name: "Prova filtre2",
    key: "provaFiltre2",
    type: "text",
    visible : false,
  },
  {
    name: "Email",
    key: "emailUsuari",
    type: "text",
    visible:true,
  },
  {
    name: "Actiu",
    key: "actiuUsuari",
    type: "select",
    visible:true,
    options: [
      {
        name: "Tots",
        value: "*",
      },
      {
        name: "Actiu",
        value: 1,
      },
      {
        name: "No actiu",
        value: 0,
      },
    ],
  },
];

//Funcions

const isActive = (cmp) => {
  if(viewComponent.value === cmp){
    return true;
  }else{
    return false
  }
}

onMounted(() => {
  userStore.fetchUsers();
});

const draggableOrder = (resultat) => {
  result.value = resultat;
};

const aplyOrder = (campOrdre, order) => {
  let objecteOrder = {}
  objecteOrder[campOrdre] = order ;
  userStore.addOrder(objecteOrder)
  userStore.fetchUsers();
};

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
  console.log(filter)
  userStore.addFilter(filter)
  userStore.fetchUsers();
};

const botoClick = (id) => {
   router.push({name: 'usuarisFitxa', params: { id:id } })
}


</script>

<style scoped>

.active{
  background-color: green !important;
}
.capcalera {
  border-bottom: 0.5px gray solid;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;
  gap: 15px;
}

.container-component {
  padding: 20px;
  background-color: #f8f9fa;
  height: 100vh;
}
</style>
