import { defineStore } from "pinia";
import { apiFech } from "@/composables/apiFetch";
const baseUrl = process.env.VUE_APP_API_URL_BASE + `/users/`;

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    users: [],
    userData: [],
    error: [],
    status: 0,
    statusUserInfo: 0,
    filter: {},
    order: {'nomUsuari':'ASC'}
  }),

  getters: {
    getUsers(state) {
      return state.users;
    },
    isLoading(state) {
      return (state.status == 2) ? true : false;
    },
    isError(state) {
      return (state.status == 3) ? true : false;
    },
    isLoadingUserInfo(state){
      return (state.statusUserInfo == 2 || state.statusUserInfo == 0) ? true : false;
    },
    isErrorUserInfo(state){
      return (state.statusUserInfo == 3) ? true : false;
    }
  },

  actions: {

    //Retorna json de usuaris

    async fetchUsers() {
      //console.log("entro a fecth");
      this.status = 2;
      //console.log("parametres a fecth " )
    
      const user = await apiFech.get(`${baseUrl}`+'?order=' +  JSON.stringify(this.order) + '&filter=' + JSON.stringify(this.filter) );
      
      console.log(user);
      if (user.status == "ok" && user.result) {
        this.users = user.result;
        this.status = 1;
      } else {
        //console.log("entro error");
        this.error = user.error;
        this.status = 3;
        return false;
      }
    },

    addOrder(campOrdre){
      this.order = campOrdre
    },
    
    addFilter(campFilter){
      this.filter = campFilter
    },

    // Retorna dades bàsiques de usuari

    async fetchUserDataBasic() {
      // console.log("entro fecth userData basiques");
      this.status = 2; 
      const fetchTMP = await apiFech.get(`${baseUrl}/${this.userData.idUsuari}`);
      //console.log(fetchTMP);
      if (fetchTMP.status == "ok" && fetchTMP.result) {
        this.userData = fetchTMP.result;
        this.status = 1;
        //console.log(this.userData);
      } else {
        this.error = fetchTMP.error;
        this.status= 3;
        return false;
      }
    },

       // Retorna dades ampliades de usuari

    async fetchUserDataInfo() {
      console.log("entro fecth userData complertes");
     
    
     
      if(this.userData.idUsuari){
        this.statusUserInfo = 2;
        
        console.log(this.userData.idUsuari)
  
        const fetchTMP2 = await apiFech.get(baseUrl + "/info/" + this.userData.idUsuari);

  
        if (fetchTMP2.status == "ok" && fetchTMP2.result) {
          this.userData = fetchTMP2.result;
          console.log(this.userData)
          this.statusUserInfo = 1;
        
        } else {
          this.errror = fetchTMP2.error;
          this.statusUserInfo = 3;
          return false;
        }
      }
     
    },

    loadUserBasic(userId) {
      console.log('entra loadUserBasic')
      this.status = 2
     
      this.userData.idUsuari = userId;
      
      this.fetchUserDataBasic()
    },

    loadUserInfo(id) {
      this.statusUserInfo=0
      console.log('entra loadUserInfo' + id)
      this.userData.idUsuari=id 
      this.fetchUserDataInfo();
      
    },

    async updateUserData() {
      console.log("entra store novaInformacio" + this.userData.idUsuari);

      const dataToPost = {
        nomUsuari: this.userData.nomUsuari,
        cognomUsuari: this.userData.cognomUsuari,
      };

      const fetchTMP3 = await apiFech.post(baseUrl + "/update/" + this.userData.idUsuari,dataToPost);

      if (fetchTMP3.status == "ok" && fetchTMP3.result) {
        console.log("modificat ok")
        //this.fetchUserDataInfo();
      
        location.reload()
      } else {
        this.errror = fetchTMP3.error;
        this.statusUserInfo = 3;
        return false;
      }

      
    },

    
  },
});
